<div class="dialog-container">
  <h2 class="title" mat-dialog-title>
    {{ "actions.change_pwd" | translate }}
  </h2>
  <form
    [formGroup]="form"
    (ngSubmit)="submitHandler(form)"
    id="change_pwd_form"
    mat-dialog-content
  >
    <div class="container-fluid g-0">
      <div class="d-flex flex-column">
        @if(isResetUserPwd){
        <app-input-field
          [label]="'users_page.new_password' | translate"
          [required]="true"
          [error]="
            (form.get('password').touched &&
              form.get('password').hasError('required')) ||
            (form.get('password').touched &&
              form.get('password').hasError('minlength'))
          "
        >
          <mat-form-field appearance="outline" class="default-input full-width">
            <input
              [type]="showPwd ? 'text' : 'password'"
              matInput
              [placeholder]="'users_page.new_password' | translate"
              formControlName="password"
              required
            />
            <mat-icon matIconSuffix (click)="toggleShowPassword()">{{
              showOldPwd ? "visibility_off" : "visibility"
            }}</mat-icon>
            @if(form.get('password').touched &&
            form.get('password').hasError('required')){
            <mat-error>
              {{ "users_page.errors.password_required" | translate }}
            </mat-error>
            } @else if (form.get('password').touched &&
            form.get('password').hasError('minlength')) {
            <mat-error>{{
              "users_page.errors.password_must_be_at_least"
                | translate : { value: 6 }
            }}</mat-error>
            }
          </mat-form-field>
        </app-input-field>
        }@else{
        <app-input-field
          [label]="'users_page.old_password' | translate"
          [required]="true"
          [error]="
            (form.get('oldPassword').touched &&
              form.get('oldPassword').hasError('required')) ||
            (form.get('oldPassword').touched &&
              form.get('oldPassword').hasError('minlength'))
          "
        >
          <mat-form-field appearance="outline" class="default-input full-width">
            <input
              [type]="showOldPwd ? 'text' : 'password'"
              matInput
              [placeholder]="'users_page.old_password' | translate"
              formControlName="oldPassword"
              required
            />
            <mat-icon matIconSuffix (click)="toggleShowOldPassword()">{{
              showOldPwd ? "visibility_off" : "visibility"
            }}</mat-icon>
            @if(form.get('oldPassword').touched &&
            form.get('oldPassword').hasError('required')){
            <mat-error>
              {{ "users_page.errors.password_required" | translate }}
            </mat-error>
            } @else if (form.get('oldPassword').touched &&
            form.get('oldPassword').hasError('minlength')) {
            <mat-error>{{
              "users_page.errors.password_must_be_at_least"
                | translate : { value: 6 }
            }}</mat-error>
            }
          </mat-form-field>
        </app-input-field>
        <app-input-field
          [label]="'users_page.new_password' | translate"
          [required]="true"
          [error]="
            (form.get('newPassword').touched &&
              form.get('newPassword').hasError('required')) ||
            (form.get('newPassword').touched &&
              form.get('newPassword').hasError('minlength'))
          "
        >
          <mat-form-field appearance="outline" class="default-input full-width">
            <input
              [type]="showNewPwd ? 'text' : 'password'"
              matInput
              [placeholder]="'users_page.new_password' | translate"
              formControlName="newPassword"
              required
            />
            <mat-icon matIconSuffix (click)="toggleShowNewPassword()">{{
              showNewPwd ? "visibility_off" : "visibility"
            }}</mat-icon>
            @if(form.get('newPassword').touched &&
            form.get('newPassword').hasError('required')){
            <mat-error>
              {{ "users_page.errors.password_required" | translate }}
            </mat-error>
            } @else if (form.get('newPassword').touched &&
            form.get('newPassword').hasError('minlength')) {
            <mat-error>{{
              "users_page.errors.password_must_be_at_least"
                | translate : { value: 6 }
            }}</mat-error>
            }
          </mat-form-field>
        </app-input-field>
        }
      </div>
    </div>
  </form>
  <div mat-dialog-actions [align]="'end'">
    <button
      type="reset"
      (click)="closeDialog()"
      mat-flat-button
      class="default-btn outline"
      form="change_pwd_form"
    >
      <mat-icon>close</mat-icon>
      <span>{{ "actions.close" | translate }}</span>
    </button>
    <button
      type="submit"
      color="primary"
      mat-flat-button
      class="default-btn rounded"
      [disabled]="isSubmitting"
      form="change_pwd_form"
    >
      <mat-icon>save</mat-icon>
      <span>{{ "actions.save" | translate }}</span>
    </button>
  </div>
</div>
